import * as Yup from 'yup';

const loginValidationSchemaES = Yup.object().shape({
  email: Yup.string()
    .email('El email ingresado no es válido.')
    .required('Email es un campo requerido.'),
  password: Yup.string().required('Contraseña es un campo requerido.'),
});

const loginValidationSchemaEN = Yup.object().shape({
  email: Yup.string()
    .email('The entered email is not valid.')
    .required('Email is a required field.'),
  password: Yup.string().required('Password is a required field.'),
});

export { loginValidationSchemaES, loginValidationSchemaEN };
