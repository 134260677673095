import { Listbox, Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { BsCheck, BsChevronDown, BsChevronExpand } from 'react-icons/bs';

const availableLanguages = [
  { label: 'En', value: 'en' },
  { label: 'Es', value: 'es' },
];

export default function LanguageSelect() {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  const handleLanguageChange = (langValue) => {
    router.push({ pathname, query }, asPath, { locale: langValue.value });
  };

  const selectedLanguage = availableLanguages.find((l) => l.value === locale);

  return (
    <Listbox
      value={availableLanguages.find((l) => l.value === locale)}
      onChange={handleLanguageChange}
    >
      <div className="relative mt-1 w-16">
        <Listbox.Button
          className={
            'relative flex gap-2 items-center justify-end w-full cursor-default rounded-lg text-left focus:outline-none focus-visible:ring-2 text-white focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm overflow-visible'
          }
        >
          <span className="block truncate">{selectedLanguage?.label}</span>
          <span className="pointer-events-none flex items-center pr-2">
            <BsChevronDown className="h-3 w-3 text-white" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-10 mt-1 w-auto bg-white rounded-md shadow-lg max-h-32 sm:text-sm ring-1 ring-black ring-opacity-5 focus:outline-none overflow-auto right-0">
            {availableLanguages.map((language, k) => (
              <Listbox.Option
                key={k}
                value={language}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active
                      ? 'bg-copper-100 text-copper-900'
                      : 'text-gray-900 bg-white'
                  }`
                }
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {language.label}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-copper-600">
                        <BsCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
