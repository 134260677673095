import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import { FcGoogle } from 'react-icons/fc';
import { Form, Formik } from 'formik';
import InputField from '../Form/Fields/InputField';
import {
  registerValidationSchemaEN,
  registerValidationSchemaES,
} from '../../validations/register';
import {
  loginValidationSchemaEN,
  loginValidationSchemaES,
} from '../../validations/login';
import { AiOutlineLoading } from 'react-icons/ai';
import recoverPasswordValidationSchema from '../../validations/recoverPassword';
import Button from '../Button';
import {
  registerWithEmailAndPassword,
  signInWithFacebook,
  signInWithGoogle,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from '../../helpers/firebase';

import { toastError, toastSuccess } from '../Common/toasters';
import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import { Web3Button } from '@web3modal/react';
import { logGtagEvent } from '@/helpers/analytics';

type ERROR_CODES = 'auth/account-exists-with-different-credential';
type ErrorMap = {
  [key in ERROR_CODES]: string;
};

const NEXT_PUBLIC_GOOGLE_ADS = process.env.NEXT_PUBLIC_GOOGLE_ADS;
const CRYPTO_ENABLED = process.env.NEXT_PUBLIC_CRYPTO_ENABLED === 'true';

const errorMessages: ErrorMap = {
  'auth/account-exists-with-different-credential':
    'Ya existe una cuenta asociada a este email, intenta ingresar con otro proveedor.',
};

type LoginSectionProps = {
  onLogin?: () => void;
};

const LoginSection = ({ onLogin }: LoginSectionProps) => {
  const router = useRouter();
  const language = router.locale as SupportedLanguages;
  const isSpanish = language === 'es';
  const [socialLoading, setSocialLoading] = useState('');
  const [socialError, setSocialError] = useState<ERROR_CODES>();
  const [registering, setRegistering] = useState(false);
  const [logingIn, setLogingIn] = useState(false);
  const [showRecoverPassword, setShowRecoverPassword] = useState(false);
  const [sendingResetPassword, setSendingResetPassword] = useState(false);
  const referral = router.query.referral;

  const handleSocialLogin = useCallback(
    (provider: string) => async () => {
      setSocialLoading(provider);
      try {
        // set referred by if available
        if (referral) {
          localStorage.setItem('referred_by', referral as string);
        }

        let response;
        if (provider === 'facebook') {
          response = await signInWithFacebook();
        } else if (provider === 'google') {
          response = await signInWithGoogle();
        }

        const name =
          response?.additionalUserInfo?.username ||
          (response?.additionalUserInfo?.profile as object & { name: string })
            ?.name;

        if (response?.additionalUserInfo?.isNewUser) {
          // TODO: send welcome email
          logGtagEvent({
            action: 'conversion',
            params: {
              send_to: NEXT_PUBLIC_GOOGLE_ADS,
            },
          });
          toastSuccess(`Gracias por registrarte ${name}!`);
        } else {
          toastSuccess(`Hola ${name}!`);
        }
      } catch (err: any) {
        console.log(Object.keys(err).map((k) => [k, err[k]]));
        localStorage.removeItem('referred_by');
        setSocialError(err?.code);
      }
      setSocialLoading('');
      onLogin?.();
    },
    [onLogin, referral]
  );

  const handleLoginSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLogingIn(true);
    try {
      const response = await signInWithEmailAndPassword(email, password);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const name = response?.user?.multiFactor?.user?.displayName;
      toastSuccess(`Hola ${name}!`);
    } catch (e) {
      toastError(
        isSpanish
          ? 'Algo salió mal tratando de iniciar sesión.'
          : 'Something went wrong trying to login.'
      );
      console.log(e);
    }
    setLogingIn(false);
    onLogin?.();
  };

  const handleRegisterSubmit = async ({
    name,
    email,
    password,
  }: {
    name: string;
    email: string;
    password: string;
  }) => {
    setRegistering(true);
    try {
      await registerWithEmailAndPassword(name, email, password);
      logGtagEvent({
        action: 'conversion',
        params: {
          send_to: NEXT_PUBLIC_GOOGLE_ADS,
        },
      });
      toastSuccess(
        isSpanish ? '¡Registro exitoso!' : 'Registration successful!'
      );
    } catch (e) {
      toastError(
        isSpanish
          ? 'Algo salió mal tratando de realizar el registro.'
          : 'Something went wrong trying to register.'
      );
    }
    onLogin?.();
    setRegistering(false);
  };

  const handleForgotPasswordClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setShowRecoverPassword(true);
  };

  const handleRecoverPasswordSubmit = async ({ email }: { email: string }) => {
    setSendingResetPassword(true);
    try {
      await sendPasswordResetEmail(email);
      toastSuccess(
        isSpanish
          ? '¡Email enviado! Revisa tu correo para reestablecer el password.'
          : 'Email sent! Check your email to reset your password.'
      );
    } catch (e) {
      console.log(e);
      toastError(
        isSpanish
          ? 'Algo salió mal tratando de enviar el email.'
          : 'Something went wrong trying to send the email.'
      );
    }
  };

  return (
    <div className="flex flex-col p-4 items-center">
      {showRecoverPassword ? (
        <>
          <div className="w-full">
            <button
              className="text-copper underline"
              onClick={() => {
                setShowRecoverPassword(false);
              }}
            >
              {isSpanish ? 'Volver' : 'Back'}
            </button>
            <p className="py-2">
              {isSpanish
                ? 'Recuperar contraseña, un e-mail con un link para recuperar tucontraseña será enviado.'
                : 'Recover password, an email with a link to reset your password will be sent.'}
            </p>
          </div>
          <Formik
            initialValues={{
              email: '',
            }}
            onSubmit={handleRecoverPasswordSubmit}
            validationSchema={recoverPasswordValidationSchema}
          >
            {({ handleSubmit }) => (
              <Form className="w-full">
                <div className="flex flex-col w-full pb-4 gap-2 items-center">
                  <InputField
                    name="email"
                    placeHolder="Email"
                    label="Email"
                    labeled
                  />
                  <Button
                    type="primary"
                    onClick={handleSubmit}
                    disabled={sendingResetPassword}
                  >
                    {isSpanish ? 'Enviar' : 'Send'}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Tab.Group>
          <Tab.List className="flex rounded-xl w-full">
            <Tab
              key="register"
              className={({ selected }) =>
                classNames(
                  'w-full rounded-l-full py-2.5 text-sm font-medium leading-5',
                  'focus:outline-none',
                  selected
                    ? 'bg-copper text-white'
                    : 'text-copper hover:bg-white/[0.12] hover:text-white border-copper border'
                )
              }
            >
              {isSpanish ? 'Regístrate' : 'Register'}
            </Tab>
            <Tab
              key="login"
              className={({ selected }) =>
                classNames(
                  'w-full rounded-r-full py-2.5 text-sm font-medium leading-5',
                  'focus:outline-none',
                  selected
                    ? 'bg-copper text-white'
                    : 'text-copper hover:bg-white/[0.12] border-copper border'
                )
              }
            >
              {isSpanish ? 'Iniciar Sesión' : 'Log In'}
            </Tab>
          </Tab.List>
          <Tab.Panels className="mt-2 w-full">
            <Tab.Panel
              key="register-tab"
              className={classNames('bg-white p-3')}
            >
              <div className="w-full flex flex-col items-center">
                {CRYPTO_ENABLED && (
                  <div className="relative w-full flex items-center justify-center gap-4 pt-4">
                    <Web3Button />
                  </div>
                )}
                <div className="relative w-full flex items-center justify-center gap-4 pb-6 pt-4">
                  <Button
                    disabled={socialLoading === 'google'}
                    onClick={handleSocialLogin('google')}
                    type="secondary"
                  >
                    <div className="flex gap-2 w-full justify-center items-center">
                      {socialLoading === 'google' ? (
                        <AiOutlineLoading
                          className="animate-spin text-copper"
                          size="2em"
                        />
                      ) : (
                        <>
                          {isSpanish
                            ? 'Continua con Google'
                            : 'Continue with Google'}
                          <FcGoogle size="1.5rem" />
                        </>
                      )}
                    </div>
                  </Button>
                  {/* <button
                    className={`
                      appearance-none
                      rounded-full
                      text-copper
                      border-copper
                      border
                      h-12
                      w-12
                      flex
                      justify-center items-center
                    `}
                    disabled={socialLoading === "facebook"}
                    onClick={handleSocialLogin("facebook")}
                  >
                    {socialLoading === "facebook" ? (
                      <Spinner />
                    ) : (
                      <FaFacebookF size="1.5em" />
                    )}
                  </button> */}
                </div>
                {socialError && (
                  <div className="text-red-700 text-center p-2">
                    {errorMessages[socialError] || isSpanish
                      ? 'Hubo un error, intenta de nuevo'
                      : 'There was an error, try again'}
                  </div>
                )}
                <div className="flex gap-2 w-full items-center">
                  <div className="border-copper border-b w-full" />
                  <div className="basis-60 shrink-0 text-center text-copper">
                    {isSpanish
                      ? 'O continua con tu correo electrónico'
                      : 'Or continue with your email'}
                  </div>
                  <div className="border-copper border-b w-full" />
                </div>
                <div className="flex flex-col w-full gap-2 items-center">
                  <Formik
                    initialValues={{
                      email: '',
                      password: '',
                      confirmPassword: '',
                      name: '',
                    }}
                    onSubmit={handleRegisterSubmit}
                    validationSchema={
                      isSpanish
                        ? registerValidationSchemaES
                        : registerValidationSchemaEN
                    }
                  >
                    {({ handleSubmit }) => (
                      <Form className="w-full">
                        <InputField
                          name="name"
                          placeHolder={isSpanish ? 'Nombre' : 'Name'}
                          label={isSpanish ? 'Nombre' : 'Name'}
                          labeled
                        />
                        <InputField
                          name="email"
                          placeHolder="Email"
                          label="Email"
                          labeled
                        />
                        <InputField
                          name="password"
                          placeHolder={isSpanish ? 'Contraseña' : 'Password'}
                          label={isSpanish ? 'Contraseña' : 'Password'}
                          type="password"
                          labeled
                        />
                        <InputField
                          name="confirmPassword"
                          placeHolder={
                            isSpanish
                              ? 'Confirmar Contraseña'
                              : 'Confirm Password'
                          }
                          label={
                            isSpanish
                              ? 'Confirmar Contraseña'
                              : 'Confirm Password'
                          }
                          type="password"
                          labeled
                        />
                        <div className="w-full flex justify-center">
                          <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={registering}
                          >
                            <div className="w-full flex justify-center">
                              {registering ? (
                                <AiOutlineLoading
                                  className="animate-spin text-white"
                                  size="2em"
                                />
                              ) : isSpanish ? (
                                'Registrar'
                              ) : (
                                'Register'
                              )}
                            </div>
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel
              key="login-tab"
              className={classNames('bg-white p-3 flex flex-col w-full')}
            >
              <div className="relative w-full flex items-center justify-center gap-4 pb-6 pt-4">
                <Button
                  disabled={socialLoading === 'google'}
                  onClick={handleSocialLogin('google')}
                  type="secondary"
                >
                  <div className="flex gap-2 w-full justify-center items-center">
                    {socialLoading === 'google' ? (
                      <AiOutlineLoading
                        className="animate-spin text-copper"
                        size="2em"
                      />
                    ) : (
                      <>
                        {isSpanish
                          ? 'Continua con Google'
                          : 'Continue with google'}{' '}
                        <FcGoogle size="1.5rem" />
                      </>
                    )}
                  </div>
                </Button>
                {/* <button
                    className={`
                      appearance-none
                      rounded-full
                      text-copper
                      border-copper
                      border
                      h-12
                      w-12
                      flex
                      justify-center items-center
                    `}
                    disabled={socialLoading === "facebook"}
                    onClick={handleSocialLogin("facebook")}
                  >
                    {socialLoading === "facebook" ? (
                      <Spinner />
                    ) : (
                      <FaFacebookF size="1.5em" />
                    )}
                  </button> */}
              </div>
              {socialError && (
                <div className="text-red-700 text-center p-2">
                  {errorMessages[socialError] || isSpanish
                    ? 'Hubo un error, intenta de nuevo'
                    : 'There was an error, try again'}
                </div>
              )}
              <div className="w-full flex flex-col items-center">
                <div className="flex gap-2 w-full items-center">
                  <div className="border-copper border-b w-full" />
                  <div className="basis-60 shrink-0 text-center text-copper">
                    {isSpanish
                      ? 'O continua con tu correo electrónico'
                      : 'Or continue with your email'}
                  </div>
                  <div className="border-copper border-b w-full" />
                </div>
                <div className="flex flex-col w-full pb-4 gap-2 items-center">
                  <Formik
                    initialValues={{
                      email: '',
                      password: '',
                    }}
                    onSubmit={handleLoginSubmit}
                    validationSchema={
                      isSpanish
                        ? loginValidationSchemaES
                        : loginValidationSchemaEN
                    }
                  >
                    {({ handleSubmit }) => (
                      <Form className="w-full">
                        <InputField
                          name="email"
                          placeHolder="Email"
                          label="Email"
                          labeled
                        />
                        <InputField
                          name="password"
                          placeHolder={isSpanish ? 'Contraseña' : 'Password'}
                          label={isSpanish ? 'Contraseña' : 'Password'}
                          type="password"
                          labeled
                        />
                        <div className="w-full flex justify-center">
                          <Button
                            type="primary"
                            onClick={handleSubmit}
                            disabled={logingIn}
                          >
                            {isSpanish ? 'Iniciar Sesión' : 'Log In'}
                          </Button>
                        </div>
                        <div className="w-full flex justify-center pt-4 pb-2">
                          <button
                            className="underline text-copper text-center"
                            onClick={handleForgotPasswordClick}
                          >
                            {isSpanish
                              ? 'Olvidé mi contraseña'
                              : 'Forgot my password'}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      )}
    </div>
  );
};

export default LoginSection;
