import 'firebase/compat/auth';
import { useRouter } from 'next/router';
import Button from './Button';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getFirebaseApp } from '../helpers/firebase';
import { currencyFormat } from '../utils';
import { toast } from 'react-toastify';

const auth = getAuth(getFirebaseApp());
const DISCOUNT_VALUE = process.env.NEXT_PUBLIC_DISCOUNT_VALUE || 10000;
const MIN_PAYMENT_FOR_DISCOUNT =
  process.env.NEXT_PUBLIC_MIN_PAYMENT_FOR_DISCOUNT || 20000;

const DiscountModal = () => {
  const [user] = useAuthState(auth);
  const [tickets, setTickets] = useState<Array<any>>([]);
  const [loadingTickets, setLoadingTickets] = useState(true);
  const router = useRouter();
  const language = router.locale as SupportedLanguages;
  const isSpanish = language === 'es';
  const referral = router.query.referral;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleLogin = useCallback(() => {
    if (referral) {
      router.push(`/login?referral=${referral}`);
      return;
    }
    router.push('/login');
  }, [referral, router]);

  useEffect(() => {
    if (loadingTickets) return;

    const discountRegisterAcknowledge = localStorage.getItem(
      'discountRegisterAcknowledge'
    );

    if (!user && !discountRegisterAcknowledge) {
      timeoutRef.current = setTimeout(() => {
        toast.success(
          <div className="flex flex-col gap-2">
            <div className="text-sm">
              <strong className="font-bold">
                {isSpanish
                  ? `¡Tikipal te regala ${currencyFormat(
                      DISCOUNT_VALUE as number
                    )} en
                  tu primera compra!`
                  : `Tikipal gives you ${currencyFormat(
                      DISCOUNT_VALUE as number
                    )} in
                  your first purchase!`}
              </strong>
              <span className="text-center">
                {user
                  ? isSpanish
                    ? `Accede a este descuento y disfruta de
                    la fiesta sin hacer filas. (Válido para compras mayores o iguales
                    a ${currencyFormat(MIN_PAYMENT_FOR_DISCOUNT as number)})`
                    : ` Access this discount and enjoy the party without waiting in line. (Valid for purchases greater than or equal to ${currencyFormat(
                        MIN_PAYMENT_FOR_DISCOUNT as number
                      )})`
                  : isSpanish
                  ? ` Accede a este descuento y disfruta de
                    la fiesta sin hacer filas. (Válido para compras mayores o iguales
                    a ${currencyFormat(MIN_PAYMENT_FOR_DISCOUNT as number)})`
                  : ` Access this discount and enjoy the party without waiting in line. (Valid for purchases greater than or equal to ${currencyFormat(
                      MIN_PAYMENT_FOR_DISCOUNT as number
                    )})`}
              </span>
            </div>
            {user ? null : (
              <Button onClick={handleLogin}>
                {isSpanish ? 'Regístrate Ahora' : 'Sign Up Now'}
              </Button>
            )}
          </div>,
          {
            position: 'top-right',
            autoClose: 30000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            icon: false,
            toastId: 'discount-toast',
          }
        );
      }, 10000);
      localStorage.setItem('discountRegisterAcknowledge', 'true');
      return;
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }
  }, [user, tickets, loadingTickets, handleLogin, isSpanish]);

  useEffect(() => {
    const getTickets = async () => {
      setLoadingTickets(true);
      try {
        if (!user) {
          setLoadingTickets(false);
          return;
        }
        const res = await fetch('/api/tickets');
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        const data = await res.json();
        setTickets(data.results.filter((ticket: TicketType) => ticket.is_paid));
      } catch (e) {
        console.log(e);
      }
      setLoadingTickets(false);
    };
    getTickets();
  }, [user]);

  return <></>;
};

export default DiscountModal;
