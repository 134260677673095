import * as Yup from 'yup';

const registerValidationSchemaES = Yup.object().shape({
  name: Yup.string().required('Nombre es un campo requerido.'),
  email: Yup.string()
    .email('El email ingresado no es válido.')
    .required('Email es un campo requerido.'),
  password: Yup.string()
    .required('Contraseña es un campo requerido.')
    .min(8, 'La contraseña debe tener al menos 8 caracteres.'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Las contraseñas deben coincidir.'
  ),
});

const registerValidationSchemaEN = Yup.object().shape({
  name: Yup.string().required('Name is a required field.'),
  email: Yup.string()
    .email('The entered email is not valid.')
    .required('Email is a required field.'),
  password: Yup.string()
    .required('Password is a required field.')
    .min(8, 'Password must be at least 8 characters long.'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match.'
  ),
});

export { registerValidationSchemaES, registerValidationSchemaEN };
